import React, { useState, useContext } from "react";
import {
  StyledAppPreview,
  StyledAppPreviewIntro,
  StyledAppPreviewIntroBigText,
  StyledAppPreviewIntroCTA,
  StyledAppPreviewIntroDescription,
  StyledAppPreviewIntroTitle,
  StyledAppPreviewView,
  StyledAppPreviewViewContent,
  StyledAppPreviewViewDescription,
  StyledAppPreviewViewImage,
  StyledAppPreviewViews,
  StyledAppPreviewViewTitle,
} from "./style";
import Icon, { icons } from "../Icon/Icon";
import { StyledGridRow } from "../../commons/Grid";
import { Colors } from "../../commons/Theme";
import Button from "../Button/Button";
import Scroller from "../Scroller/Scroller";
import { DataContext } from "../../contexts/DataContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default ({
  hasGradient, theme, intro, views, allImages
}) => {
  const isWhite = theme === "whiteFull";
  const isFull = theme === "whiteFull";
  const [currentView, setCurrentView] = useState(0);
  const { i18nLink } = useContext(DataContext);


  return (
    <StyledAppPreview id="apppreview" hasGradient={hasGradient} isWhite={isWhite}>
      <StyledGridRow noMargin>
        <StyledAppPreviewIntro noCta={!intro.cta}>
          {intro.title && (
            <StyledAppPreviewIntroTitle
              dangerouslySetInnerHTML={{ __html: intro.title }}
            />
          )}
          {intro.bigText && (
            <StyledAppPreviewIntroBigText
              dangerouslySetInnerHTML={{ __html: intro.bigText }}
            />
          )}
          {intro.cta && (
            <StyledAppPreviewIntroCTA>
              <Button as="div" noPadding theme="transparent" to={intro.cta.url ? i18nLink(`${intro.cta.url}`) : null}>
                {intro.cta.label}{" "}
                <Icon icon={icons.arrowRight} color={Colors.white} />
              </Button>
            </StyledAppPreviewIntroCTA>
          )}
          <StyledAppPreviewIntroDescription
            dangerouslySetInnerHTML={{ __html: intro.description }}
          />
        </StyledAppPreviewIntro>
      </StyledGridRow>
      <StyledGridRow isFullMobile noMargin={isWhite}>
        <Scroller mobile>
          <StyledAppPreviewViews>
            {views.map((view, index) => {
              const imageData = allImages.find(e => e.path === view.image);
              return (
                <StyledAppPreviewView
                  key={index}
                  isActive={currentView === index}
                >
                  <StyledAppPreviewViewContent
                    onMouseOver={() => setCurrentView(index)}
                  >
                    <StyledAppPreviewViewTitle>
                      <Icon icon={icons[view.icon]} color={Colors.yellow} />
                      {view.title}
                    </StyledAppPreviewViewTitle>
                    <StyledAppPreviewViewDescription
                      dangerouslySetInnerHTML={{ __html: view.description }}
                    />
                  </StyledAppPreviewViewContent>
                  <StyledAppPreviewViewImage>
                    <GatsbyImage image={getImage(imageData)} alt={imageData.alt} />
                  </StyledAppPreviewViewImage>
                </StyledAppPreviewView>
              )
            })}
          </StyledAppPreviewViews>
        </Scroller>
      </StyledGridRow>
    </StyledAppPreview>
  );
};
